import React from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleClick = () => {
    navigate("/historylist");
  };

  return (
    <div>
      <div className="menu-bar">
        <div className="header-button">
          <div className="header-button-style">
            <button className="header-button-style1" onClick={handleHomeClick}>
              홈
            </button>
            <button onClick={handleClick}>대여 기록</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
