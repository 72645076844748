import React from "react";
import EquipmentRentalList from "../Equipment/EquipmentRentalList";
import EquipmentRentalAvailableList from "../Equipment/EquipmentRentalAvailableList";
import "./DualView.css";

const DualView = () => {
  return (
    <div className="dual-view-container">
      <div className="equipment-rental-list">
        <EquipmentRentalList />
      </div>
      <div className="equipment-rental-available-list">
        <EquipmentRentalAvailableList />
      </div>
    </div>
  );
};

export default DualView;
