import React, { useEffect, useState } from "react";
import moment from "moment";
import "./HistoryList.css";
import {
  getDepartmentList,
  getUsersByDepartment,
  getUserRentalRecordsByDepartment,
} from "../../api/equipmentApi";
import Pagination from "../../pages/Pagination/Pagination";

export default function HistoryList() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedUserOption, setSelectedUserOption] = useState("");
  const [options, setOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  // Pagination 관련 상태
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const pageCount = 10;
  //페이지네이션이 정보 초기화가 안되도록 설정
  const [isPaginationOnChange, setIsPaginationOnChange] = useState(false);

  // 페이지네이션 데이터
  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  /**
   * 부서 데이터 조회
   */
  const fetchOption = async () => {
    try {
      const response = await getDepartmentList();

      console.log("API 응답 데이터:", response.data);
      if (response.data.result) {
        setOptions(response.data.data);
        //alert("조회를 성공했습니다.");
        //window.location.reload();
      } else {
        alert("조회 실패: " + response.data.message);
      }
    } catch (error) {
      console.error("에러:", error);
      alert("네트워크 오류로 조회할 수 없습니다.");
    }
  };

  // 부서나 사용자 선택 시 데이터 초기화
  useEffect(() => {
    if (selectedOption || (selectedUserOption && !isPaginationOnChange)) {
      setData([]); // 데이터 초기화
    }
    setIsPaginationOnChange(false);
  }, [selectedOption, selectedUserOption, isPaginationOnChange]);

  /**
   * 유저 데이터 조회
   * @param {*} department_id
   */
  const fetchUserOption = async (department_id) => {
    try {
      const response = await getUsersByDepartment(department_id);

      console.log("API 응답 데이터:", response.data);
      if (response.data.result) {
        setUserOptions(response.data.data);
        //alert("조회를 성공했습니다.");
        //window.location.reload();
      } else {
        alert("조회 실패: " + response.data.message);
      }
    } catch (error) {
      console.error("에러:", error);
      alert("네트워크 오류로 조회할 수 없습니다.");
    }
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  /**
   * 대여기록 조회
   */
  const fetchData = async () => {
    try {
      const response = await getUserRentalRecordsByDepartment(
        selectedUserOption
      );
      if (response.data) {
        if (response.data.result) {
          setData(response.data.data); // 유효한 데이터가 있을 경우 설정
        } else {
          alert("대여기록이 없습니다.");
          setError("조회 실패: " + response.data.message);
        }
      } else {
        console.log("응답 데이터가 없습니다.");
      }
    } catch (error) {
      console.error("에러", error);
      setError("네트워크 오류로 데이터를 조회할 수 없습니다.");
    }
  };

  useEffect(() => {
    fetchOption();
  }, []);

  /**
   * 부서 선택
   * @param {} event
   */
  const handleChange = (event) => {
    const selectedDepartment = event.target.value;
    setSelectedOption(selectedDepartment);
    if (selectedDepartment) {
      fetchUserOption(selectedDepartment);
    } else {
      setUserOptions([]);
      alert("유저 정보를 불러올 수 없습니다.");
    }
  };

  /**
   * 유저 선택
   * @param {} event
   */
  const handleUser = (event) => {
    const selectedUserId = event.target.value;
    setSelectedUserOption(selectedUserId);
  };

  const handleSearch = () => {
    if (selectedOption && selectedUserOption) {
      fetchData();
    } else {
      alert("부서와 사용자를 선택해 주세요.");
    }
  };

  /**
   * 페이지 변경 핸들러
   */
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="history-list-total">
      <h1>대여 기록</h1>
      <div className="select-total">
        <div className="select-item">
          <label htmlFor="department">부서</label>
          <select
            id="department"
            value={selectedOption}
            onChange={handleChange}
          >
            <option value="department">부서 선택</option>
            {options.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="select-item">
          <label htmlFor="user">사용자</label>
          <select
            id="user"
            value={selectedUserOption}
            onChange={handleUser}
            disabled={!selectedOption}
          >
            <option value="">사용자 선택</option>
            {userOptions.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button className="search-button" onClick={handleSearch}>
            검색
          </button>
        </div>
      </div>

      <div>
        <table>
          <thead>
            <tr>
              <th>번호</th>
              <th>제품명</th>
              <th>모델명</th>
              <th>시리얼 번호</th>
              <th>IMEI</th>
              <th>OS버전</th>
              <th>OneUi버전</th>
              <th>상세설명</th>
              <th>대여일</th>
              <th>반납일</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item, index) => {
              const itemNumber = (currentPage - 1) * itemsPerPage + index + 1;
              return (
                <tr key={item.id}>
                  <td>{itemNumber}</td>
                  <td>{item.product_name || "N/A"}</td>
                  <td>{item.model_name || "N/A"}</td>
                  <td>{item.serial_number || "N/A"}</td>
                  <td>{item.imei_number || "N/A"}</td>
                  <td>{item.os_version || "N/A"}</td>
                  <td>{item.one_ui_version || "N/A"}</td>
                  <td>{item.description || "-"}</td>
                  <td>{formatDate(item.rentalAt) || "오류"}</td>
                  <td>
                    {item.returnAt ? formatDate(item.returnAt) : "대여중"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        totalItems={data.length}
        itemCountPerPage={itemsPerPage}
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
//{item.description || "-"} 이방법은 단순한 로직을 판단하기 위함 =>formatDate처럼 로직(복잡한 로직)이 없는 경우에 사용함.
//{item.returnAt ? formatDate(item.returnAt) : "대여중"} 의 방법은 item.returnAt값이 null이거나 undefined이라면 대여중으로 표현하라는 의미.
