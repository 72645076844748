import React, { useEffect, useState } from "react";
import "./EquipmentList.css";
import moment from "moment";
import "moment/locale/ko";
import {
  getBorrowedEquipment,
  putReturnEquipment,
} from "../../api/equipmentApi";
import Pagination from "../../pages/Pagination/Pagination";
import SideTopButton from "../Scroll/SideTopButton";
import LoadingErrorHandler from "../Loading/LoadingErrorHandler";

export default function EquipmentRentalList() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Pagination 관련 상태
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8; // 페이지당 아이템 수
  const pageCount = 10; // 표시할 페이지 수

  // 페이지네이션 데이터
  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    borrowedEquipmentList();
  }, [currentPage]);

  const borrowedEquipmentList = async () => {
    try {
      setLoading(true); // 로딩 시작
      const response = await getBorrowedEquipment();
      if (response.data.result) {
        setData(response.data.data);
      } else {
        setError("대여 중인 장비 List를 불러올 수 없습니다."); // 에러 메시지 설정
      }
    } catch (error) {
      //console.error("에러:", error);
      setError("데이터를 불러올 수 없습니다."); // 에러 메시지 설정
    } finally {
      setLoading(false); // 로딩 완료
    }
  };

  /**
   *날짜 형태 변환
   * @param {Date} date
   * @returns
   */
  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  /**
   * 장비 반납하기
   * @param {*} userId
   * @param {*} id
   * @returns
   */
  const handleRent = async (userId, id) => {
    try {
      //put해서 데이터를 보내려고 한다면 이 과정을 통해 변수를 정리하여 보낼 수 있음.
      const rentalRequest = {
        userId: userId,
        equipmentId: id,
      };

      if (!userId) {
        alert("사용자 ID를 확인하세요.");
        return;
      }
      if (!id) {
        alert("장비 ID를 확인하세요.");
        return;
      }

      const response = await putReturnEquipment(rentalRequest);

      if (response.data.result) {
        alert("반납을 성공했습니다.");
        window.location.reload();
      } else {
        alert("반납 실패: " + response.data.message);
      }
    } catch (error) {
      //console.error("에러:", error);
      console.error("데이터를 불러올 수 없습니다.");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="rental-list-total">
      <h1>대여 중인 장비 List</h1>
      <LoadingErrorHandler isLoading={loading} error={error}>
        {error ? (
          <p>{error}</p> // 에러 메시지 표시
        ) : (
          <table>
            <thead>
              <tr>
                <th>번호</th>
                <th>제품명</th>
                <th>모델명</th>
                <th>시리얼 번호</th>
                <th>IMEI</th>
                <th>OS버전</th>
                <th>OneUi버전</th>
                <th>상세설명</th>
                <th>사용자</th>
                <th>대여일</th>
                <th>반납</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, index) => {
                const itemNumber = (currentPage - 1) * itemsPerPage + index + 1;
                return (
                  <tr key={item.id}>
                    <td>{itemNumber}</td>
                    <td>{item.product_name || "N/A"}</td>
                    <td>{item.model_name || "N/A"}</td>
                    <td>{item.serial_number || "N/A"}</td>
                    <td>{item.imei_number || "N/A"}</td>
                    <td>{item.os_version || "N/A"}</td>
                    <td>{item.one_ui_version || "N/A"}</td>
                    <td>{item.description || "-"}</td>
                    <td>{item.userName || "오류"}</td>
                    <td>{formatDate(item.rentalAt) || "오류"}</td>
                    <td>
                      {item.status === "RENTED" || !item.status ? (
                        <button
                          onClick={() => handleRent(item.userId, item.id)}
                        >
                          반납
                        </button>
                      ) : (
                        "오류"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <Pagination
          totalItems={data.length}
          itemCountPerPage={itemsPerPage}
          pageCount={pageCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
        <SideTopButton />
      </LoadingErrorHandler>
    </div>
  );
}
