import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import "./LoadingErrorHandler.css";

const LoadingErrorHandler = ({ isLoading, error, children }) => {
  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="error-title">
        <h1>잠시 후 다시 시도해주세요</h1>
        <p>{error}</p>
      </div>
    );
  }

  return <>{children}</>;
};

export default LoadingErrorHandler;
