import axios from "axios";

export const API_BASE_URL = process.env.REACT_APP_API_ROOT;

/**
 * 장비 대여 중인 api
 * @returns 장비 대여 중인 값
 */
export const getBorrowedEquipment = async () => {
  const endpoint = "/api/equipment/borrowed";
  const apiUrl = API_BASE_URL + endpoint;
  // console.log(apiUrl);
  return await axios.get(apiUrl);
  //호출되는 함수이므로 await를 사용하여 작성해야 한다.
};

/**
 * 장비 반납하는 api
 * axios.put의 두 번째 인자는 PUT 요청의 본문(body)입니다.
 *  현재 userId와 id를 직접 전달하고 있는데, 이들은 객체의 프로퍼티로 묶어야 합니다.
 * @returns api주소
 */
//아래의 형식이 property 형식이다
//{name(이름/키) : value(값)} 의 형태로 이루어져 있으며, object의 속성
// userId: number,
// id: number,
// }): Promise<any>
//이런 타입은 .ts, .tsx에서 사용이 가능하다. 그래서 js는 다른 형식으로 코드 구형해야 한다.
//따로 메서드를 만들어서 값을 받아오는 형식으로 해야 함.
//
//js는 아래와 같이 구현할 수가 없음
// interface putData {
//   userId: number;
//   id: number;
// }

export async function putReturnEquipment(data) {
  const endpoint = "/api/rental/return";
  const apiUrl = API_BASE_URL + endpoint;
  try {
    const response = await axios.put(apiUrl, data);
    return response;
  } catch (error) {
    console.error("에러:", error);
    throw error;
  }
}

/**
 * 장비 대여 가능한 api
 */
export const getAvailableEquipment = async () => {
  const endpoint = "/api/equipment/available";
  const apiUrl = API_BASE_URL + endpoint;
  return await axios.get(apiUrl);
};

/**
 * 유저 데이터 api
 * 부서에 대한 유저의 정보(이름)을 가져오는 거임.
 */
export const getUsersByDepartment = async (data) => {
  const endpoint = "/api/user?department_id=";
  const apiUrl = API_BASE_URL + endpoint;
  try {
    const response = await axios.get(apiUrl + data);
    return response;
  } catch (error) {
    console.error("에러:", error);
    throw error;
  }
};

/**
 * 대여하기(부서, 유저, 대여일)
 */

export async function postRentEquipment(data) {
  const endpoint = "/api/rental";
  const apiUrl = API_BASE_URL + endpoint;
  try {
    const response = await axios.post(apiUrl, data);
    return response;
  } catch (error) {
    console.error("에러:", error);
    throw error;
  }
}

/**
 *
 * @returns 부서 목록
 */
export const getDepartmentList = async () => {
  const endpoint = "/api/user/department";
  const apiUrl = API_BASE_URL + endpoint;
  try {
    const response = await axios.get(apiUrl);
    return response;
  } catch (error) {
    console.error("에러:", error);
    throw error;
  }
};
/**
 * 대여기록 api
 */
export async function getUserRentalRecordsByDepartment(data) {
  const endpoint = "/api/rental?userId=";
  const apiUrl = API_BASE_URL + endpoint;
  try {
    const response = await axios.get(apiUrl + data);
    return response;
  } catch (error) {
    console.error("에러:", error);
    throw error;
  }
}

/**
 * Promise 이부분이 비동기 처리에 활용되는 객체임=> 웹서비스를 구현할 때 원활한 데이터 통신을 위해 활용됨.
 * 결국 데이터를 모두 받기 전에 웹에 출력하는 것을 방지하기 위함.
 *
 */
// const sendPutRequest = async (   sendPutRequest라는 이름의 비동기 함수
//sync 키워드를 사용하여 이 함수가 비동기적으로 실행된다는 것을 나타냅니다. 즉, 이 함수는 Promise를 반환
//   apiUrl: string,       apiUrl: string 타입의 매개변수로, PUT 요청을 보낼 API의 URL을 지정
//   data: { pcSystemId: number, verId: number }
//data: { pcSystemId: number, verId: number } 타입의 객체로, PUT 요청의 본문에 포함될 데이터를 지정합니다. 이 객체는 두 개의 숫자 속성, pcSystemId와 verId를 포함
// ): Promise<any> => {
//   const response = await axios.put(apiUrl, data);
//   return response.data;
// };
