import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Pagination.module.css";

export default function Pagination({
  totalItems,
  itemCountPerPage,
  pageCount,
  currentPage,
  onPageChange,
}) {
  const totalPage = Math.ceil(totalItems / itemCountPerPage);
  const [start, setStart] = useState(1);
  const noPrev = start === 1;
  const noNext = start + pageCount - 1 >= totalPage;

  useEffect(() => {
    if (currentPage === start + pageCount) setStart((prev) => prev + pageCount);
    if (currentPage < start) setStart((prev) => prev - pageCount);
  }, [currentPage, pageCount, start]);

  return (
    <div className={styles.wrapper}>
      <ul>
        <li className={`${styles.move} ${noPrev ? styles.invisible : ""}`}>
          <Link
            to={`?page=${start - 1}`}
            onClick={() => onPageChange(start - 1)}
          >
            이전
          </Link>
        </li>
        {[...Array(pageCount)].map((_, i) => {
          const page = start + i;
          return (
            page <= totalPage && (
              <li key={i}>
                <Link
                  className={`${styles.page} ${
                    currentPage === page ? styles.active : ""
                  }`}
                  to={`?page=${page}`}
                  onClick={() => onPageChange(page)}
                >
                  {page}
                </Link>
              </li>
            )
          );
        })}
        <li className={`${styles.move} ${noNext ? styles.invisible : ""}`}>
          <Link
            to={`?page=${start + pageCount}`}
            onClick={() => onPageChange(start + pageCount)}
          >
            다음
          </Link>
        </li>
      </ul>
    </div>
  );
}
