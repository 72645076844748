import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import MyDatePicker from "../../components/MyDatePicker";
import "./EquipmentList.css";
import "moment/locale/ko";
import {
  getAvailableEquipment,
  getUsersByDepartment,
  postRentEquipment,
  getDepartmentList,
} from "../../api/equipmentApi";
import Pagination from "../../pages/Pagination/Pagination";
import LoadingErrorHandler from "../Loading/LoadingErrorHandler";

Modal.setAppElement("#root"); //모달 사용 시 추가해야 함.

export default function EquipmentRentalAvailableList() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedUserOption, setSelectedUserOption] = useState("");
  const [options, setOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  // const [selectedUser, setSelectedUser] = useState(null);
  const [rentalDate, setRentalDate] = useState(null);

  // Pagination 관련 상태
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8; // 페이지당 아이템 수
  const pageCount = 10; // 표시할 페이지 수
  const [loading, setLoading] = useState(true);

  // 페이지네이션 데이터
  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  /**
   * 모달 스타일
   */
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      width: "620px",
      height: "230px",
      margin: "auto",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
      padding: "20px",
      overflow: "visible",
    },
  };

  /**
   * 모달 열기
   * 모달 열었을 때 부서를 선택할 수 있도록 하고 싶으면 부서를 열때-> 조회해놔야 함.
   */
  const openModal = (equipment) => {
    setSelectedEquipment(equipment); //장비 id도 전달하는 건 필수
    setIsOpen(true);
    fetchOption();
  };

  /**
   * 모달 닫기
   */
  const closeModel = () => {
    setIsOpen(false);
    setSelectedOption(""); //옵션 초기화하는 문구가 있어야 오류로 인한 데이터 손실이 줄어든다
    setUserOptions([]);
    setRentalDate(null);
  };

  useEffect(() => {
    availableEquipmentList();
  }, [currentPage]);

  /**
   * 부서 데이터 조회
   */
  const fetchOption = async () => {
    try {
      const response = await getDepartmentList();
      console.log("API 응답 데이터:", response.data);
      if (response.data.result) {
        setOptions(response.data.data);
        //alert("조회를 성공했습니다.");
        //window.location.reload();
      } else {
        alert("조회 실패: " + response.data.message);
      }
    } catch (error) {
      console.error("에러:", error);
      alert("네트워크 오류로 조회할 수 없습니다.");
    }
  };

  /**
   * 유저 데이터 조회
   * @param {*} sdepartment_id
   */
  const fetchUserOption = async (department_id) => {
    try {
      const response = await getUsersByDepartment(department_id);

      if (response.data.result) {
        console.log("조회를 성공했습니다");
        setUserOptions(response.data.data);
      } else {
        alert("조회 실패: " + response.data.message);
      }
    } catch (error) {
      console.error("에러:", error);
      alert("네트워크 오류로 조회할 수 없습니다.");
    }
  };

  /**
   * 대여 가능한 데이터 api
   */
  const availableEquipmentList = async () => {
    try {
      setLoading(true);
      const response = await getAvailableEquipment();
      if (response.data.result) {
        setData(response.data.data);
      } else {
        setError("조회 실패");
      }
    } catch (error) {
      //console.error("에러:", error);
      setError("데이터를 불러올 수 없습니다.");
    } finally {
      setLoading(false);
    }
  };

  /**
   * 부서 선택
   * @param {} event
   */
  const handleChange = (event) => {
    const selectedDepartment = event.target.value;
    setSelectedOption(selectedDepartment);
    if (selectedDepartment) {
      fetchUserOption(selectedDepartment);
    } else {
      setUserOptions([]);
      alert("유저 정보를 불러올 수 없습니다.");
    }
  };

  /**
   * 유저 선택
   * @param {} event
   */
  const handleUser = (event) => {
    const selectedUserId = event.target.value;
    setSelectedUserOption(selectedUserId);
  };

  /**
   * 날짜 선택
   * @param {Date} date
   */
  const handleDate = (date) => {
    const now = new Date();
    // 선택된 날짜를 가져와 시간만 현재 시간으로 설정합니다.
    const selectedDateWithTime = new Date(date);
    selectedDateWithTime.setHours(
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
      now.getMilliseconds()
    );

    setRentalDate(selectedDateWithTime);
    console.log("Selected Date with Current Time:", selectedDateWithTime);
    // setRentalDate(date);
    // console.log("Selected Date:", date);
  };

  /**
   * 날짜를 KST 기준 ISO 8601 형식으로 포맷팅하는 과정
   * @param {Date} date
   * @returns {string}
   */
  const formatDateToKSTISOString = (date) => {
    const KST_OFFSET_MS = 9 * 60 * 60 * 1000; // 9시간을 밀리초로 변환
    const kstDate = new Date(date.getTime() + KST_OFFSET_MS);

    // KST 기준 ISO 8601 형식으로 변환 => 스프링의 시간 형태로 변환하기 위함.
    const isoString = kstDate.toISOString();
    console.log(isoString);
    return isoString;
  };

  /**
   * 대여할 수 있도록 정보를 Post
   */
  const handleRent = async () => {
    try {
      if (!selectedOption) {
        alert("부서를 선택해주세요.");
        return;
      }
      if (!selectedUserOption) {
        alert("사용자를 선택해주세요.");
        return;
      }
      if (!rentalDate) {
        alert("날짜를 선택해주세요.");
        return;
      }

      const formattedDate = formatDateToKSTISOString(rentalDate);

      const rentalRequest = {
        equipmentId: selectedEquipment,
        dapartmentId: selectedOption,
        userId: selectedUserOption,
        rentalAt: formattedDate,
      };

      const response = await postRentEquipment(rentalRequest);

      if (response.data.result) {
        setRentalDate(response.data.data);
        alert("대여를 성공했습니다.");
        setIsOpen(false); // 모달 닫기
        setSelectedOption(""); // 선택 초기화
        setSelectedEquipment(null); // 선택된 장비 초기화
        setSelectedUserOption(""); // 선택된 사용자 초기화
        setRentalDate(null); // 대여 날짜 초기화
        window.location.reload(); // 페이지 새로고침
      } else {
        alert("대여 실패: " + response.data.message);
      }
    } catch (error) {
      // console.error("에러:", error);
      alert("네트워크 오류로 대여할 수 없습니다.");
    }
  };

  /**
   * 페이지 변경 핸들러
   */
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="list-total">
      <h1>대여 가능한 장비 List</h1>
      <LoadingErrorHandler isLoading={loading} error={error}>
        {error ? (
          <p>{error}</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>번호</th>
                <th>제품명</th>
                <th>모델명</th>
                <th>시리얼 번호</th>
                <th>IMEI</th>
                <th>OS버전</th>
                <th>OneUi버전</th>
                <th>상세설명</th>
                <th>대여</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, index) => {
                const itemNumber = (currentPage - 1) * itemsPerPage + index + 1;
                return (
                  <tr key={item.id}>
                    <td>{itemNumber}</td>
                    <td>{item.product_name || "N/A"}</td>
                    <td>{item.model_name || "N/A"}</td>
                    <td>{item.serial_number || "N/A"}</td>
                    <td>{item.imei_number || "N/A"}</td>
                    <td>{item.os_version || "N/A"}</td>
                    <td>{item.one_ui_version || "N/A"}</td>
                    <td>{item.description || "-"}</td>
                    <td>
                      <button onClick={() => openModal(item.id)}>대여</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        <Modal isOpen={isOpen} onRequestClose={closeModel} style={customStyles}>
          <h3>대여 정보</h3>
          <div className="modal-content">
            <div className="select-option">
              <div className="select-item">
                <label htmlFor="department">부서</label>
                <select
                  id="department"
                  value={selectedOption}
                  onChange={handleChange}
                >
                  <option value="">부서 선택</option>
                  {options.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select-user-item">
                <label htmlFor="user">사용자</label>
                <select
                  id="user"
                  value={selectedUserOption}
                  onChange={handleUser}
                  disabled={!selectedOption} //부서가 선책되지 않으면 비활성화되도록 하는 방법
                >
                  <option value="">사용자 선택</option>
                  {userOptions.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select-item">
                <label htmlFor="rental-date">대여일</label>
                <MyDatePicker
                  selected={rentalDate}
                  dateFormat="yyyy-MM-dd"
                  onChange={handleDate}
                  placeholderText="날짜 선택"
                  disabled={!selectedUserOption}
                />
              </div>
            </div>
            <div className="buttonContainer">
              <button onClick={closeModel}>닫기</button>
              <button onClick={handleRent}>제출</button>
            </div>
          </div>
        </Modal>

        <Pagination
          totalItems={data.length}
          itemCountPerPage={itemsPerPage}
          pageCount={pageCount}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </LoadingErrorHandler>
    </div>
  );
}
