import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";

const MyDatePicker = ({
  selected,
  onChange,
  dateFormat,
  placeholderText,
  disabled,
}) => {
  return (
    <div className={`datepicker-container ${disabled ? "disabled" : ""}`}>
      {!disabled ? (
        <DatePicker
          selected={selected}
          onChange={onChange}
          dateFormat={dateFormat}
          placeholderText={placeholderText}
          className="custom-datepicker"
          locale={ko}
        />
      ) : (
        <input
          type="text"
          placeholder={placeholderText}
          className="custom-datepicker disabled"
          readOnly
        />
      )}
    </div>
  );
};

export default MyDatePicker;
